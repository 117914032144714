:root {
  --color1: #3B373C;
  --color2: #D2B084;
  --color3: #AE8087;
  --color4: #D7C8C2;
  --color5: #d1d3c7;
  --color6: #eee9e0;
  --color7: #faf9f8;

  --font1: 'Bellefair';
  --font2: 'Dream Catcher';
  --font3: 'Baskerville Display';

}
#root {
  height: 100vh;
}
#mobileDeviceNotice {
  display: none;
}
html {
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header h1 {
  color: var(--color1);
  font-size: 5vh;
}

.mainContent {
  height: 80vh;

}



h1 {
  font-family: var(--font2);
  color: var(--color3);
  font-size: 7vh;
}

h2 {
  font-family: var(--font2);
  color: var(--color1);
  font-size: 5vh;
}

h6 {
  text-align: center;
  font-size: 20px;

}

.App {
  height: auto;
  text-align: left;

}

.logo {
  justify-content: left;
  width: 60%;
  margin-left: -10px;
}

/* /////////////////////////////NAV////////////////////// */

.topnav {
  overflow: hidden;
  background-image: url(./assets/BG3.JPG);
  background-repeat: round;
  position: sticky;
  border-radius: 20px;
  background-color: var(--color1);
  background-position: 0px 0px;
  /* background-repeat: repeat-x; */
  animation: animatedBackground 30s linear infinite;
}

.topnav #myLinks {
  display: none;
  text-align: center;
  
}


.topnav #myLinks a {
  flex-direction: column;
}
.myLinksOne {
  width: 50%;
  text-shadow: 1px 1px 15px var(--color3);
  
}
.myLinksOne a:hover {
  color: var(--color3);
  opacity: .5;
  border-radius: 40px;
  font-weight: 100;

}

.myLinksTwo {
  width: 50%;
}

.topnav a {
  color: var(--color1);
  padding: 10px 12px;
  text-decoration: none;
  font-family: var(--font1);
  font-size: 25px;
  display: block;
}

.topnav a.icon {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin: 18px;
  font-size: 35px;
}

.topnav a:hover {
  
}


.active {
  color: var(--color4);
}



.fa-bars {
  font-size: 35px;
  color: var(--color3);
  
}
/* ///////////////////////////////////////home.js///////////////////// */

.aboutUsContainer {
  text-align: center;
  padding: 10px;
}
.aboutUsContent {
  font-family: var(--font1);
  color: var(--color1);
  text-align: center;
  padding: 10px;
  letter-spacing: 2px;
}
.aboutUsContent h1 {
  text-align: start;
}
.aboutUsContent img {
  width: 300px;
}

/* SHOPPING PAGE */

.shopifyHdr {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  height: 40px;
  background-color: var(--color2);
}

.footer {

}

/* ////////////////////////////KEYFRAMES////////////////////////////// */

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes animatedButton {
  from {
    font-weight: lighter;
  }
  to {
    font-weight: bolder;
  }
}
@keyframes animationTest {
  from {
    display: flex;
  }
  to {
    display: none;
  }
}


/* //////////////////////////FONTS/////////////////////////// */

@font-face {
  font-family: 'Dream Catcher';
  src: url('../src/assets/fonts/Dream Catcher.ttf'); /* IE9 Compat Modes */
  src: url('../src/assets/fonts/Dream Catcher.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('../src/assets/fonts/Dream Catcher.ttf') format('ttf'), /* Modern Browsers */
       url('../src/assets/fonts/Dream Catcher.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../src/assets/fonts/Dream Catcher.ttf') format('svg'); /* Legacy iOS */
}

/* ////////////////////////// MEDIA QUERIES ////////////////////////// */




@media screen and (min-width: 900px) {
  #root {
    visibility: hidden;
  }
  #mobileDeviceNotice {
    display: block;
    text-align: center;
  }
}